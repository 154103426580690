import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import {
  makeStyles,
  styled,
} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import globalStyles from 'src/styles/globalStyles'

import {
  Container as MuiContainer,
  Hidden,
  Typography,
  Grid as MuiGrid,
  Box,
  AppBar,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  IconButton,
  Tabs,
  Tab,
  Button as MuiButton
} from '@material-ui/core'

import Layout from 'src/components/layout'
import { parseAlt } from 'src/helpers'

const Container = styled(MuiContainer)(spacing)
const Grid = styled(MuiGrid)(spacing)
const Button = styled(MuiButton)(spacing)

const useStyles = makeStyles(theme => ({
  ...globalStyles,
  brick: {
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '4px',
    backgroundColor: '#505050',
    '& h2': {
      fontWeight: 800,
      color: 'white'
    },
    height: 'calc((100vh - 94px)/3 - 10px)',
    '&:hover': {
      '& $brickImage': {
        transform: 'scale(1.05)',
        filter: 'blur(4px)'
      }
    }
  },
  cover: {
    width: '1000px',
    height: '1000px',
    position: 'absolute',
    transform: 'rotate(40deg)',
    top: '-600px',
    left: '-700px',
    // transform: 'rotate(70deg)',
    // top: '-800px',
    // left: '-200px',
    backgroundColor: 'rgba(56, 113, 194,0.7)'
  },
  cover1: {
    transform: 'rotate(60deg)',
    top: '-700px',
    left: '-500px',
  },
  cover3: {
    left: '-600px',
  },
  brickContent: {
    position: 'absolute',
    // backgroundColor: 'rgba(56, 113, 194, 0.7)',
    // borderBottomRightRadius: '10px',
    '& p': {
      fontSize: '1rem',
      fontWeight: '500',
      color: 'white',
      margin: '0px',
      paddingTop: theme.spacing(1.5)
    }
  },
  brickImage: {
    position: 'absolute',
    height: '100%',
    top: '0px',
    opacity: '0.5',
    objectFit: 'cover',
    objectPosition: 'center center',
    transition: 'transform .2s, filter .2s'
  },
  bannerHeader: {
    fontSize: '1.4rem'
  },
  fullWidth: {
    width: '100%'
  },
  [theme.breakpoints.up('md')]: {
    brick1: {
      height: 'calc(100vh - 118px)',
    },
    brick2: {
      height: 'calc((100vh - 118px)/2 - 8px)',
    },
    brick3: {
      height: 'calc((100vh - 118px)/2 - 8px)',
    },
    bannerHeader: {
      fontSize: '2.2rem'
    },
    brickContent: {
      '& p': {
        fontSize: '1.2rem',
      }
    },
  },
}))

const toAbsolutePath = (path, pageContext) => `${pageContext.langPrefix}${path}`

const Landing = ({ data, pageContext }) => {
  const LandingPage = data.contentfulLandingPage
  const classes = useStyles()
  const theme = useTheme()
  const bptMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Layout pageContext={pageContext}>
      <Grid className={classes.fullWidth} container spacing={bptMd ? 2 : 1} mb={1} m={0} px={bptMd ? 1 : 0.5}>
        <Grid item xs={12} md={6}>
          <Box className={`${classes.brick} ${classes.brick1}`}>
            <Link to={toAbsolutePath(LandingPage.bannerLink1.path, pageContext)}>
              <GatsbyImage className={classes.brickImage} image={getImage(LandingPage.bannerPicture1)} alt={parseAlt(LandingPage.bannerPicture1, pageContext)} />
              {/*}<div className={`${classes.cover} ${classes.cover1}`}></div>*/}
              <Box className={classes.brickContent} p={bptMd ? 4 : 2}>
                <Typography className={`${classes.bannerHeader} ${classes.headerLine}`} variant="h3" component="h2">
                  {LandingPage.bannerHeader1}
                </Typography>
                <p>{LandingPage.bannerCaption1}</p>
                {/*<Box>
                  <Button size="small" variant="contained" color="secondary" disableElevation mt={2.5}>Czytaj więcej</Button>
                </Box>*/}
              </Box>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={`${classes.brick} ${classes.brick2}`} mb={bptMd ? 2 : 1}>
            <Link to={toAbsolutePath(LandingPage.bannerLink2.path, pageContext)}>
              <GatsbyImage className={classes.brickImage} image={getImage(LandingPage.bannerPicture2)} alt={parseAlt(LandingPage.bannerPicture2, pageContext)} />
              {/*}<div className={classes.cover}></div>*/}
              <Box className={classes.brickContent} p={bptMd ? 4 : 2}>
                <Typography className={`${classes.bannerHeader} ${classes.headerLine}`} variant="h3" component="h2">
                  {LandingPage.bannerHeader2}
                </Typography>
                <p>{LandingPage.bannerCaption2}</p>
                {/*}<Box>
                  <Button size="small" variant="contained" color="secondary" disableElevation mt={2.5}>Czytaj więcej</Button>
                </Box>*/}
              </Box>
            </Link>
          </Box>
          <Box className={`${classes.brick} ${classes.brick3}`}>
            <Link to={toAbsolutePath(LandingPage.bannerLink3.path, pageContext)}>
              <GatsbyImage className={classes.brickImage} image={getImage(LandingPage.bannerPicture3)} alt={parseAlt(LandingPage.bannerPicture3, pageContext)} />
              {/*}<div className={`${classes.cover} ${classes.cover3}`}></div>*/}
              <Box className={classes.brickContent} p={bptMd ? 4 : 2}>
                <Typography className={`${classes.bannerHeader} ${classes.headerLine}`} variant="h3" component="h2">
                  {LandingPage.bannerHeader3}
                </Typography>
                <p>{LandingPage.bannerCaption3}</p>
                {/*<Box>
                  <Button size="small" variant="contained" color="secondary" disableElevation mt={2.5}>Czytaj więcej</Button>
                </Box>*/}
              </Box>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Landing

export const pageQuery = graphql`
  query LandingPageQuery($id: String!) {
    contentfulLandingPage(id: { eq: $id }) {
      bannerHeader1
      bannerCaption1
      bannerLink1 {
        path
      }
      bannerPicture1 {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      bannerHeader2
      bannerCaption2
      bannerLink2 {
        path
      }
      bannerPicture2 {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      bannerHeader3
      bannerCaption3
      bannerLink3 {
        path
      }
      bannerPicture3 {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
